import { render, staticRenderFns } from "./Elements.vue?vue&type=template&id=60170208&scoped=true&"
import script from "./Elements.vue?vue&type=script&lang=js&"
export * from "./Elements.vue?vue&type=script&lang=js&"
import style0 from "./Elements.vue?vue&type=style&index=0&id=60170208&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60170208",
  null
  
)

export default component.exports